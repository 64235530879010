import {
  getTaskStatistics,
  deleteComment,
  createComments,
  getComments,
  deleteAttachmentFiles,
  addAttachmentFiles,
  getAttachmentFiles,
  getTimeLineHistory,
  getTaskSteps,
  deleteAccount,
  updateAccount,
  createAccount,
  getOneAccount,
  getAccounts,
  getDownloadAccounts,
  getContracts,
  createContract,
  updateContract,
  deleteContract,
  getContractList,
  getPaymetHistory,
  updateComment,
  updateAccountActDoc,
  updateAccountUpdateDoc,
} from '@/api/taskManager'

export default {
  //ACCOUNTS
  FETCH_ACCOUNTS(context, params) {
    return getAccounts(params).then(response => {
      context.state.accounts = response.data
      return response.data
    })
  },

  FETCH_ONE_ACCOUNT(context, id) {
    return getOneAccount(id).then(response => {
      context.state.one_account = response.data
      return response.data
    })
  },

  CREATE_ACCOUNT(_, data) {
    return createAccount(data)
  },

  UPDATE_ACCOUNT(_, data) {
    return updateAccount(data)
  },

  UPDATE_ACCOUNT_ACT_DOC(_, data) {
    return updateAccountActDoc(data)
  },

  UPDATE_ACCOUNT_UPDATE_DOC(_, data) {
    return updateAccountUpdateDoc(data)
  },
  DELETE_ACCOUNT(_, id) {
    return deleteAccount(id)
  },

  //STEPS

  FETCH_STEPS(context, params) {
    return getTaskSteps(params).then(response => {
      context.state.steps = response.data
      return response.data
    })
  },

  //STATISTICS
  FETCH_ACCOUNT_STATISTICS(context, params) {
    return getTaskStatistics(params).then(response => {
      context.state.account_statistics = response.data
      return response.data
    })
  },

  //COMMENTS
  FETCH_COMMENTS(context, params) {
    return getComments(params).then(response => {
      context.state.comments = response.data
      return response.data
    })
  },
  POST_COMMENT(_, data) {
    return createComments(data)
  },
  DELETE_COMMENT(_, id) {
    return deleteComment(id)
  },

  UPDATE_COMMENT(_, data) {
    return updateComment(data)
  },

  //FILES
  FETCH_ATTACHMENT_FILES(context, query) {
    return getAttachmentFiles(query).then(response => {
      context.state.attachments = response.data
      return response.data
    })
  },
  UPLOAD_ATTACHMENT_FILE(_, data) {
    return addAttachmentFiles(data)
  },
  DELETE_ATTACHMENT_FILE(_, id) {
    return deleteAttachmentFiles(id)
  },

  //TIME LINE HISTORY
  FETCH_TIME_LINE_HISTORY(context, params) {
    return getTimeLineHistory(params).then(response => {
      context.state.task_time_line_history = response.data
      return response.data
    })
  },

  async FETCH_DOWNLOAD_EXCEL(_, query) {
    const response = await getDownloadAccounts(query)
    console.log('response', response)
    return response.data

    // return getDownloadAccounts(query).then(response => {
    //   console.log('response', response)
    //   // context.state.attachments = response.data
    //   return response.data
    // })
  },

  //TODO CONTRACTS

  FETCH_CONTRACTS(context, params) {
    return getContracts(params).then(response => {
      context.state.contracts = response.data
      return response.data
    })
  },

  FETCH_CONTRACTS_LIST(context, params) {
    return getContractList(params).then(response => {
      context.state.contracts_list = response.data
      return response.data
    })
  },

  CREATE_CONTRACT(_, data) {
    return createContract(data)
  },

  UPDATE_CONTRACT(_, data) {
    return updateContract(data)
  },
  DELETE_CONTRACT(_, id) {
    return deleteContract(id)
  },

  //TODO PAYMENT HISTORY

  FETCH_PAYMENT_HISTORY(context, params) {
    return getPaymetHistory(params).then(response => {
      context.state.payment_history = response.data
      return response.data
    })
  },
}
