import state from './knowledgeBaseState'
import actions from './knowledgeBaseActions'
import getters from './knowledgeBaseGetters'

export default {
  namespaced: true,
  state,
  actions,
  getters,
}
