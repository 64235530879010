import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import breadcrumb from './breadcrumb'
import systemMonitor from "./system-monitor"
import knowledgeBase from './knowledgeBase'
import taskManager from './taskManager/index.js'
import users from './user-management/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reRenderCounter: 0,
  },
  mutations: {
    SET_RE_RENDER_COUNT(state) {
      state.reRenderCounter++
    },
  },
  getters: {
    GET_RE_RENDER_COUNT(state) {
      return state.reRenderCounter
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    breadcrumb,
      systemMonitor, 
    //asych requests
    knowledgeBase,
    taskManager,
    users,
  },
  strict: process.env.DEV,
})
