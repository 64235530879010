import {
  getRoles,
  getUsers,
  getUsersList,
  createUser,
  getOneUser,
  updateUser,
  deleteUser,
} from '@/api/userManagement'

export default {
  //TODO USERS -------------------------------------------------------------------------------------------------------
  FETCH_USERS(context, params) {
    return getUsers(params).then(response => {
      context.state.users = response.data
      return response.data
    })
  },

  FETCH_USERS_LIST(context) {
    return getUsersList().then(response => {
      context.state.users_list = response.data
      return response.data
    })
  },

  FETCH_ONE_USER(context, id) {
    return getOneUser(id).then(response => {
      context.state.one_user = response.data
      return response.data
    })
  },

  CREATE_USER(_, data) {
    return createUser(data)
  },

  UPDATE_USER(_, data) {
    return updateUser(data)
  },
  DELETE_USER(_, id) {
    return deleteUser(id)
  },

  //TODO ROLES ------------------------------------------------------------------------------------------------------------
  FETCH_ROLES(context, params) {
    return getRoles(params).then(response => {
      context.state.roles = response.data
      return response.data
    })
  },
}
