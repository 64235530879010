import state from './tasksState'
import getters from './tasksGetters'
import actions from './tasksActions'

export default {
  namespaced: true,
  getters,
  actions,
  state,
}
