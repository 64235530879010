import http from '@/libs/axios'

// TODO BILL TYPE------------------------------------------
export function getBillType(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/bill_type/',
    params,
  })
}

export function getOneBillType(id) {
  return http({
    url: `/knowledge_base/bill_type/${id}/`,
    method: 'get',
  })
}
export function getBillTypeList() {
  return http({
    method: 'GET',
    url: '/knowledge_base/bill_type/get_bill_type_list/',
  })
}

export function createBillType(data) {
  return http({
    url: '/knowledge_base/bill_type/',
    method: 'post',
    data,
  })
}

export function updateBillType({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/bill_type/${id}/`,
    data,
  })
}

export function deleteBillType(id) {
  return http({
    method: 'DELETE',
    url: `/knowledge_base/bill_type/${id}/`,
  })
}

//TODO CITY--------------------------------------

export function getCities(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/cities/',
    params,
  })
}
export function getOneCity(id) {
  return http({
    url: `/knowledge_base/cities/${id}/`,
    method: 'get',
  })
}
export function getCitiesList() {
  return http({
    method: 'GET',
    url: '/knowledge_base/cities/get_city_list/',
  })
}

export function createCity(data) {
  return http({
    url: '/knowledge_base/cities/',
    method: 'post',
    data,
  })
}

export function updateCity({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/cities/${id}/`,
    data,
  })
}

export function deleteCity(id) {
  return http({
    method: 'DELETE',
    url: `/knowledge_base/cities/${id}/`,
  })
}

//TODO PAYERS ------------------------------------

export function getPayers(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/payers/',
    params,
  })
}

export function getPayersList(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/payers/get_payer_list/',
    params,
  })
}

export function createPayer(data) {
  return http({
    url: '/knowledge_base/payers/',
    method: 'post',
    data,
  })
}

export function getOnePayer(id) {
  return http({
    url: `/knowledge_base/payers/${id}/`,
    method: 'get',
  })
}

export function updatePayer({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/payers/${id}/`,
    data,
  })
}

export function deletePayer(id) {
  return http({
    method: 'DELETE',
    url: `/knowledge_base/payers/${id}/`,
  })
}

//TODO CONTRACTOR ----------------------------------

export function getContractor(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/contractor/',
    params,
  })
}

export function getContractorList() {
  return http({
    method: 'GET',
    url: '/knowledge_base/contractor/get_contractor_list/',
  })
}

export function createContractor(data) {
  return http({
    url: '/knowledge_base/contractor/',
    method: 'post',
    data,
  })
}

export function getOneContractor(id) {
  return http({
    url: `/knowledge_base/contractor/${id}/`,
    method: 'get',
  })
}

export function updateContractor({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/contractor/${id}/`,
    data,
  })
}

export function deleteContractor(id) {
  return http({
    method: 'DELETE',
    url: `/knowledge_base/contractor/${id}/`,
  })
}

export function getContractorWithInn(params) {
  return http({
    url: `/knowledge_base/contractor/get_contractor_by_inn/`,
    method: 'get',
    params,
  })
}

// TODO CONTRACTOR USER ---------------------------------------
export function getUserPayers(params) {
  return http({
    method: 'GET',
    url: `/knowledge_base/payers/${params.id}/get_payer_user/`,
    params,
  })
}

export function updateUserPayer({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/payers/${id}/add_or_remove_from_user/`,
    data,
  })
}
//TODO SETTLEMENT ACCOUNT

export function getSettlementAccounts(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/settlement_account/',
    params,
  })
}

export function getSettlementAccountsList(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/settlement_account/get_settlement_account_list/',
    params,
  })
}

// /knowledge_base/settlement_account / get_settlement_account_by_contractor /
  
export function getSettlementAccountByContractor(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/settlement_account/get_settlement_account_by_contractor/',
    params,
  })
}

export function createSettlementAccount(data) {

  return http({
    url: '/knowledge_base/settlement_account/',
    method: 'post',
    data,
  })
}

export function getOneSettlementAccount(id) {
  return http({
    url: `/knowledge_base/settlement_account/${id}/`,
    method: 'get',
  })
}

export function updateSettlementAccount({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/settlement_account/${id}/`,
    data,
  })
}

export function deleteSettlementAccount(id) {
  return http({
    method: 'DELETE',
    url: `/knowledge_base/settlement_account/${id}/`,
  })
}
