export default [
  {
    path: '/system-status',
    name: 'system-status',
    component: () => import('@/views/Administrator/System/index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Администрирование',
          i18n: 'administration.title',
          active: true,
        },
        {
          text: 'Состояние системы',
          active: false,
        },
      ],
    },
  },
]
