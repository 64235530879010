export default [
  {
    path: '/document-type',
    name: 'document-type',
    component: () => import('@/views/Reference/DocumentType'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Справочники',
          i18n: 'manual.title',
          active: true,
        },
        {
          text: 'Тип документов ',
          i18n: 'manual.doc_type',
          active: false,
        },
      ],
    },
  },

  {
    path: '/payers',
    name: 'payers',
    component: () => import('@/views/Reference/Payers'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Справочники',

          active: true,
        },
        {
          text: 'Плательщики',

          active: false,
        },
      ],
    },
  },
  {
    path: '/citys',
    name: 'citys',
    component: () => import('@/views/Reference/Citys'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Справочники',

          active: true,
        },
        {
          text: 'Города',

          active: false,
        },
      ],
    },
  },
  {
    path: '/counter-parties',
    name: 'counter-parties',
    component: () => import('@/views/Reference/CounterParties'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Справочники',

          active: true,
        },
        {
          text: 'Контрагенты',
          active: false,
        },
      ],
    },
  },
]
