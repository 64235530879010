export default [
  {
    path: '/contracts-list',
    name: 'contracts-list',
    component: () => import('@/views/TaskManager/ContractList'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Менеджер задач',
          active: true,
        },
        {
          text: 'Список договоров',

          active: false,
        },
      ],
    },
  },
  {
    path: '/task-table',
    name: 'task-table',
    component: () => import('@/views/TaskManager/TaskList/index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'task_manager.header',
          i18n: 'task_manager.header',
          active: true,
        },
        {
          text: 'Список задач',
          i18n: 'client.list_account',
          active: false,
        },
      ],
    },
  },
  {
    path: '/task-detail/:id',
    name: 'task-detail',
    component: () => import('@/views/TaskManager/TaskList/Detail/Detail.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Менеджер задач',
          // i18n: 'task_manager.task_list',
          active: true,
        },

        {
          text: 'Список счетов',
          // i18n: 'task_manager.task_list',
          active: false,
          to: '/task-table',
        },
        {
          text: 'Счет',
          // i18n: 'task_manager.task',
          active: true,
        },
      ],
    },
  },
  {
    path: '/checking-account',
    name: 'checking-account',
    component: () => import('@/views/TaskManager/CheckingAccount/index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Менеджер задач',
          // i18n: 'task_manager.task_list',
          active: true,
        },

        {
          text: 'Расчетный счет',
          // i18n: 'task_manager.task_list',
          active: false,
          to: '/task-table',
        },
      ],
    },
  },
]
