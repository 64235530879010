import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import SearchSlot from '@/common/SearchSlot'
import AddButton from '@/common/AddButton'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import theme from './mixins/theme'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/acl'

import '@core/scss/vue/libs/quill.scss'

import toasts from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import getLang from '@/mixins/global'
import formatter from '@/mixins/formmatter'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import saveFilter from './mixins/saveFilter'
import extra from '@/mixins/extra'
import loader from '@/mixins/loader'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// Mixins
Vue.mixin(getLang)
Vue.mixin(toasts)
Vue.mixin(extra)
Vue.mixin(areYouSure)
Vue.mixin(formatter)
Vue.mixin(saveFilter)
// Миксин тема
Vue.mixin(loader);
Vue.mixin(theme)
Vue.mixin(togglePasswordVisibility)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API

Vue.use(VueCompositionAPI)

// global components

Vue.component('SearchSlot', SearchSlot)
Vue.component('AddButton', AddButton)

// import core styles
require('@core/scss/core.scss')
require('@core/scss/vue/libs/vue-select.scss')
require('@core/scss/vue/pages/page-auth.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
