import { getSystemMonitor } from '@/api/systemMonitor'

export default {
  async FETCH_SYSTEM_MONITOR({ commit }) {
    const response = await getSystemMonitor()
    commit('SET_SYSTEM_MONITOR', response.data)
    // console.log('response.data', response)
    return response.data
  },
}
