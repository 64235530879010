import state from './userManagementState'
import actions from './userManagementActions'
import getters from './userManagementGetters'

export default {
  namespaced: true,
  state,
  actions,
  getters,
}
