export default {
  //users
  users: {
    results: [],
    count: 0,
  },
  users_list: [],
  one_user: {},

  //roles
  roles: {
    results: [],
  },
}
