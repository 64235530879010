export default {
  system_monitor: {
    data: [],
    system: {
      cpu: {},
      cpu_info: {
        cores: [],
        current_frequency: {},
        min_frequency: {},
        physical_cores: {},
        total_cores: {},
        total_cpu_usage: {},
      },
      disk: [],
      swap_memory: {
        percentage: {},
        used: {},
        total: {},
      },
      swmem_memory: {
        percentage: {},
        used: {},
        total: {},
        available: {},
      },
      system_info: {
        machine: {},
        node_name: {},
        processor: {},
        release: {},
        system: {},
        version: {},
      },
      volume: [],
    },
  },
  report_workers_table: {
    results: [],
  },
}
