import {
  getBillType,
  getOneBillType,
  deleteBillType,
  createBillType,
  getBillTypeList,
  updateBillType,
  getCities,
  getCitiesList,
  getOneCity,
  createCity,
  updateCity,
  deleteCity,
  getPayers,
  getOnePayer,
  getPayersList,
  updatePayer,
  deletePayer,
  createPayer,
  getPayerWithInn,
  getContractor,
  getContractorList,
  getOneContractor,
  createContractor,
  updateContractor,
  deleteContractor,
  getContractorWithInn,
  getUserPayers,
  updateUserPayer,
  getSettlementAccounts,
  getSettlementAccountsList,
  createSettlementAccount,
  updateSettlementAccount,
  deleteSettlementAccount,
  getSettlementAccountByContractor,
} from '@/api/knowledgeBase'

export default {
  //TODO BILL TYPE
  FETCH_BILL_TYPE(context, params) {
    return getBillType(params).then(response => {
      context.state.bill_type = response.data
      return response.data
    })
  },

  FETCH_BILL_TYPE_LIST(context, params) {
    return getBillTypeList(params).then(response => {
      context.state.bill_type_list = response.data
      return response.data
    })
  },

  FETCH_ONE_BILL_TYPE(context, id) {
    return getOneBillType(id).then(response => {
      context.state.one_bill_type = response.data
      return response.data
    })
  },

  CREATE_BILL_TYPE(_, data) {
    return createBillType(data.data)
  },

  UPDATE_BILL_TYPE(_, data) {
    return updateBillType(data)
  },
  DELETE_BILL_TYPE(_, id) {
    return deleteBillType(id)
  },

  //TODO CITIES
  FETCH_CITIES(context, params) {
    return getCities(params).then(response => {
      context.state.cities = response.data
      return response.data
    })
  },

  FETCH_CITIES_LIST(context, params) {
    return getCitiesList(params).then(response => {
      context.state.cities_list = response.data
      return response.data
    })
  },

  FETCH_ONE_CITY(context, id) {
    return getOneCity(id).then(response => {
      context.state.one_city = response.data
      return response.data
    })
  },

  CREATE_CITY(_, data) {
    return createCity(data.data)
  },

  UPDATE_CITY(_, data) {
    return updateCity(data)
  },
  DELETE_CITY(_, id) {
    return deleteCity(id)
  },

  //TODO PAYERS

  FETCH_PAYERS(context, params) {
    return getPayers(params).then(response => {
      context.state.payers = response.data
      return response.data
    })
  },

  FETCH_PAYERS_LIST(context, params) {
    return getPayersList(params).then(response => {
      context.state.payers_list = response.data
      return response.data
    })
  },

  FETCH_ONE_PAYER(context, id) {
    return getOnePayer(id).then(response => {
      context.state.one_payer = response.data
      return response.data
    })
  },

  CREATE_PAYER(_, data) {
    return createPayer(data.data)
  },

  UPDATE_PAYER(_, data) {
    return updatePayer(data)
  },
  DELETE_PAYER(_, id) {
    return deletePayer(id)
  },

  FETCH_PAYER_WITH_INN(context, data) {
    return getPayerWithInn(data).then(response => {
      return response.data
    })
  },

  //TODO CONTRACTOR

  FETCH_CONTRACTORS(context, params) {
    return getContractor(params).then(response => {
      context.state.contractors = response.data
      return response.data
    })
  },

  FETCH_CONTRACTORS_LIST(context, params) {
    return getContractorList(params).then(response => {
      context.state.contractors_list = response.data
      return response.data
    })
  },

  FETCH_ONE_CONTRACTOR(context, id) {
    return getOneContractor(id).then(response => {
      context.state.one_contractor = response.data
      return response.data
    })
  },

  CREATE_CONTRACTOR(_, data) {
    return createContractor(data.data)
  },

  UPDATE_CONTRACTOR(_, data) {
    return updateContractor(data)
  },
  DELETE_CONTRACTOR(_, id) {
    return deleteContractor(id)
  },

  FETCH_CONTRACTOR_WITH_INN(context, data) {
    return getContractorWithInn(data).then(response => {
      return response.data
    })
  },

  //TODO CONTRACTOR USER

  FETCH_USER_PAYERS(context, params) {
    return getUserPayers(params).then(response => {
      context.state.user_payers = response.data
      return response.data
    })
  },

  UPDATE_USER_PAYER(_, data) {
    return updateUserPayer(data)
  },
  //TODO SETTLEMENT ACCOUNTS
  FETCH_SETTLEMENT_ACCOUNTS(context, params) {
    return getSettlementAccounts(params).then(response => {
      context.state.settlement_accounts = response.data
      return response.data
    })
  },

  FETCH_SETTLEMENT_ACCOUNTS_LIST(context, params) {
    return getSettlementAccountsList(params).then(response => {
      context.state.settlement_accounts_list = response.data
      return response.data
    })
  },

  FETCH_SETTLEMENT_ACCOUNT_BY_CONTRACTOR(context, params) {
    return getSettlementAccountByContractor(params).then(response => {
      context.state.settlement_account_by_contractor = response.data
      return response.data
    })
  },

  CREATE_SETTLEMENT_ACCOUNT(_, data) {
    return createSettlementAccount(data)
  },

  UPDATE_SETTLEMENT_ACCOUNT(_, data) {
    return updateSettlementAccount(data)
  },
  DELETE_SETTLEMENT_ACCOUNT(_, id) {
    return deleteSettlementAccount(id)
  },
}
