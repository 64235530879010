<template>
  <BRow class="mb-2">
    <BCol md="2" sm="3">
      <slot name="search"></slot>
    </BCol>
    <BCol class="d-flex justify-content-end align-items-end">
      <slot name="button"></slot>
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>
