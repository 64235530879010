import http from '@/libs/axios'

//TODO USERS ---------------------------------------------
export function getUsers(params) {
  return http({
    url: '/users/users/',
    method: 'get',
    params,
  })
}

export function getUsersList() {
  return http({
    url: '/users/users/get_users_list/',
    method: 'get',
  })
}

export function createUser(data) {
  return http({
    url: '/users/users/',
    method: 'post',
    data: data.data,
  })
}

export function getOneUser(params) {
  return http({
    url: `/users/users/${params.id}/`,
    method: 'get',
  })
}

export function updateUser(data) {
  return http({
    url: `/users/users/${data.id}/`,
    method: 'PATCH',
    data: data.data,
  })
}

export function deleteUser(id) {
  return http({
    url: `/users/users/${id}/`,
    method: 'delete',
  })
}

//roles
export function getRoles(params) {
  return http({
    url: `/users/roles/`,
    method: 'get',
    params,
  })
}
