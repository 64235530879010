export default {
  GET_BILL_TYPE: state => state.bill_type,
  GET_BILL_TYPE_LIST: state => state.bill_type_list,
  GET_ONE_BILL_TYPE: state => state.one_bill_type,

  GET_CITIES: state => state.cities,
  GET_CITIES_LIST: state => state.cities_list,
  GET_ONE_CITY: state => state.one_city,

  GET_PAYERS: state => state.payers,
  GET_PAYERS_LIST: state => state.payers_list,
  GET_ONE_PAYER: state => state.one_payer,

  GET_CONTRACTORS: state => state.contractors,
  GET_CONTRACTORS_LIST: state => state.contractors_list,
  GET_ONE_CONTRACTOR: state => state.one_contractor,

  GET_USER_PAYERS: state => state.user_payers,
  GET_SETTLEMENT_ACCOUNTS: state => state.settlement_accounts,
  GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR: state=>state.settlement_account_by_contractor
}
