export default {
  GET_ACCOUNTS: state => state.accounts,

  GET_STEPS: state => state.steps,
  GET_TIME_LINE_HISTORY: state => state.task_time_line_history,
  GET_COMMENTS: state => state.comments,
  GET_ATTACHMENTS: state => state.attachments,
  GET_ACCOUNT_STATISTICS: state => state.account_statistics,
  GET_ONE_ACCOUNT: state => state.one_account,
  GET_CONTRACTS: state => state.contracts,
  GET_CONTRACTS_LIST: state => state.contracts_list,
  GET_PAYMENT_HISTORY: state => state.payment_history,
}
