export default [
  {
    locale: 'uz',
    img: require('@/assets/images/flags/uz.png'), // eslint-disable-line global-require
    name: 'O`zbek',
  },
  {
    locale: 'ru',
    img: require('@/assets/images/flags/ru.png'), // eslint-disable-line global-require
    name: 'Русский',
  },
]
