export default {
  accounts: {
    results: [],
  },

  one_account: {
    author: {},
    current_step: {
      title: {},
      color: '',
    },
    settlement_account: {},
    file: {},
    payer: {},
    city: {},
    contractor: {},
    contract: {},
  },

  steps: [],

  account_statistics: [],

  comments: [],
  attachments: {
    results: [],
  },
  task_time_line_history: [],
  contracts: {
    results: [],
  },
  contracts_list: [],

  payment_history: {
    results: [],
  },
}
