<template>
  <b-button variant="primary" @click="$emit('onClick')">
    <feather-icon icon="PlusIcon" />
    {{ $t('general.add') }}
  </b-button>
</template>

<script>
  import { BButton } from 'bootstrap-vue'

  export default {
    components: {
      BButton,
    },
  }
</script>

<style></style>
