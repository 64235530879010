export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Administrator/Users/index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Администрирование',
          i18n: 'administration.title',
          active: true,
        },
        {
          text: 'Пользователи',
          i18n: 'administration.users',
          active: false,
          to: '/users',
        },
      ],
    },
  },

  {
    path: '/users/add',
    name: 'users-add',
    component: () => import('@/views/Administrator/Users/edit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Администрирование',
          i18n: 'administration.title',
          active: true,
        },
        {
          text: 'Пользователи',
          i18n: 'administration.users',
          active: false,
          to: '/users',
        },
        {
          text: 'Add',
          i18n: 'general.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/Administrator/Users/edit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Администрирование',
          i18n: 'administration.title',
          active: true,
        },
        {
          text: 'Пользователи',
          i18n: 'administration.users',
          active: false,
          to: '/users',
        },
        {
          text: 'Редактирование',
          i18n: 'administration.edit_route',
          active: false,
        },
      ],
    },
  },
]
