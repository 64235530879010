import http from '@/libs/axios'

export function getTaskStatistics(params) {
  return http({
    method: 'GET',
    url: '/task_manager/accounts/get_monthly_statistics/',
    params,
  })
}

export function getComments(params) {
  return http({
    url: 'files/comments/',
    method: 'GET',
    params,
  })
}
export function createComments(data) {
  return http({
    url: 'files/comments/',
    method: 'POST',
    data,
  })
}
export function deleteComment(id) {
  return http({
    url: `files/comments/${id}/`,
    method: 'DELETE',
  })
}

export function updateComment({ data, id }) {
  return http({
    method: 'PATCH',
    url: `files/comments/${id}/`,
    data,
  })
}

export function getAttachmentFiles(params) {
  return http({
    url: '/files/attachments/',
    method: 'GET',
    params,
  })
}
export function addAttachmentFiles(data) {
  return http({
    url: '/files/attachments/',
    method: 'POST',
    data,
  })
}
export function deleteAttachmentFiles(id) {
  return http({
    url: `/files/attachments/${id}/`,
    method: 'DELETE',
  })
}

export function getTaskSteps(params) {
  return http({
    method: 'GET',
    url: `/task_manager/steps/`,
    params,
  })
}

export function getTimeLineHistory(params) {
  return http({
    method: 'GET',
    url: `/task_manager/account_step_history/`,
    params,
  })
}

//ILMA

export function getAccounts(params) {
  return http({
    method: 'GET',
    url: `task_manager/accounts/`,
    params,
  })
}

export function createAccount(data) {
  return http({
    method: 'POST',
    url: '/task_manager/accounts/',
    data,
  })
}
export function getOneAccount(id) {
  return http({
    method: 'GET',
    url: `task_manager/accounts/${id}/`,
  })
}

export function updateAccount({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/task_manager/accounts/${id}/`,
    data,
  })
}

export function updateAccountActDoc({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/task_manager/accounts/${id}/delete_act/`,
    data,
  })
}

export function updateAccountUpdateDoc({ data, id }) {
  return http({
    method: 'PATCH',
    url: `/task_manager/accounts/${id}/update_act/`,
    data,
  })
}

export function deleteAccount(id) {
  return http({
    url: `/task_manager/accounts/${id}/`,
    method: 'DELETE',
  })
}

export function getDownloadAccounts(params) {
  return http({
    method: 'GET',
    url: `task_manager/export/account/`,
    params,
    responseType: 'blob',
  })
}

//TODO CONTRACT

export function getContracts(params) {
  return http({
    method: 'GET',
    url: 'task_manager/contract/',
    params,
  })
}

export function getContractList(params) {
  return http({
    method: 'GET',
    url: 'task_manager/contract/get_contract_list/',
    params,
  })
}

export function createContract(data) {
  return http({
    url: 'task_manager/contract/',
    method: 'post',
    data,
  })
}

export function updateContract({ data, id }) {
  return http({
    method: 'PATCH',
    url: `task_manager/contract/${id}/`,
    data,
  })
}

export function deleteContract(id) {
  return http({
    method: 'DELETE',
    url: `task_manager/contract/${id}/`,
  })
}

//TODO payment history

export function getPaymetHistory(params) {
  return http({
    method: 'GET',
    url: 'task_manager/account_payment_history/',
    params,
  })
}
