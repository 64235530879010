export default {
  bill_type: {
    results: [],
  },
  bill_type_list: [],
  one_bill_type: {},

  cities: {
    results: [],
  },
  cities_list: [],
  one_city: {},

  payers: {
    results: [],
  },
  payers_list: [],
  one_payer: {},

  contractors: {
    results: [],
  },
  contractors_list: [],
  one_contractor: {},
  user_payers: [],
  settlement_accounts: {
    results: [],
  },

  settlement_accounts_list: [],
  one_settlement_account: {},
  settlement_account_by_contractor: []
}
